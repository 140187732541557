
import { Watch,Mixins,Component, Vue, ProvideReactive } from "vue-property-decorator";
import PrescriptionProductListCom from "./components/productList.vue";

import { presCategoryTypeList, getPresCategoryList } from "@/api/prescriptionDb"

import { cloneDeep } from "lodash";

@Component({
    components: {
        PrescriptionProductListCom
    }
})
export default class PrescriptionProductList extends Vue {
    @ProvideReactive()
    merchantId: string|number = 0;

    categoryList = [];

    @ProvideReactive()
    categoryInfo = {} as any;

    mounted() {
        this.getData();
    }
    
    getData() {
      getPresCategoryList({merchantId: this.merchantId}).then((res) => {
        this.categoryList = res.data;
        this.categoryInfo = res.data[0];
      })
    }

    getProductList(category) {
        this.categoryInfo = cloneDeep(category);
    }

}
